import React from 'react';
import { withStyles, Typography, IconButton, Input, FormControl, InputLabel, Tooltip } from '@material-ui/core'
import SelectReact, { components } from 'react-select';
import api from '../../../services/api';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CadastroPaciente from '../../Cadastros/Paciente';

class InfoAgendamento extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            nm_paciente: this.props.paciente.nm_paciente,
            cd_paciente: this.props.paciente.cd_paciente,
            dt_nascimento: this.props.paciente.dt_nascimento,
            tp_sexo: this.props.paciente.tp_sexo,

            /*Convenio e Planos */
            isDisabledConvenio: true,
            isLoadingConvenio: false,

            isDisabledPlano: true,
            isLoadingPlano: false,

            convenios: [],
            convenio: null,
            selectedConvenio: this.props.convenio,

            planosConvenios: [],
            planosConvenio: null,
            selectedPlano: this.props.plano,
            /*-------------------- */

            cd_empresa: this.props.empresa,

        }
        this.props.cd_atendimento != null && this.getConvenioPlanoAtendimento();
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeConvenio = this.handleChangeConvenio.bind(this)
        this.handleChangePlano = this.handleChangePlano.bind(this)
    }
    componentDidMount() {
        this.getConvenio()
    }
    /*----------------------------------------PACIENTE-----------------------------------------*/
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });
    };
    setCdPaciente = (cd_paciente, nm_paciente, nr_cpf, dt_nascimento, tp_sexo) => {
        this.setState({ cd_paciente: cd_paciente, nm_paciente: nm_paciente, nr_cpf, dt_nascimento, tp_sexo })
    }
    /*-----------------------------------------------------------------------------------------*/

    /*----------------------------------CONVENIO E PLANOS------------------------------------- */
    getConvenioPlanoAtendimento = () => {
        api.post('/getConvenioPlanoAtendimento', {
            atendimento: this.props.cd_atendimento
        }).then(response => {
            this.setState({ selectedConvenio: { value: response.data[0].cd_convenio, label: response.data[0].nm_convenio }, selectedPlano: { value: response.data[0].cd_con_pla, label: response.data[0].ds_con_pla } })
        }).catch(error => {
            console.log(error)
        })
    }
    getConvenio = () => {
        this.setState({ isLoadingConvenio: true })
        api.post('/convenio', {
            cd_empresa: this.state.cd_empresa
        }).then(response => {
            this.setState({ convenios: response.data, isLoadingConvenio: false, isDisabledConvenio: false, isLoadingPlano: true })
            this.getPlano(this.state.selectedConvenio.value);
        }).catch(error => {
            console.log(error)
        })
    }
    getPlano = cd_convenio => {
        api.post('/planosConvenio', {
            cd_convenio: cd_convenio,
            empresa: this.state.cd_empresa
        }).then(response => {
            this.setState({ planosConvenios: response.data, isDisabledPlano: false, isLoadingPlano: false })
        }).catch()
    }
    handleChangeConvenio = (event) => {
        this.setState({ selectedConvenio: event, selectedPlano: null, isLoadingPlano: true, isDisabledPlano: true })
        this.getPlano(event.value)
    };
    handleChangePlano = (event) => {
        this.setState({ selectedPlano: event })
        this.props.getExame(this.state.selectedConvenio, event, this.state.cd_empresa)
        // this.getExame(this.state.selectedConvenio.value, event.value, this.state.exames[0].cd_multi_empresa);
    };
    /*------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------CADASTRO PAC------------------------------------------------------- */
    handleCloseCadPaciente = () => {
        this.setState({ cadastroPaciente: false })
    }
    /**------------------------------------------------------------------------------------------------------------------------------------------ */
    retornarStates = () => {
        return { ...this.state }
    }
    render() {
        const { classes } = this.props;
        return (
            <div style={{ margin: '20px', border: '1px solid #ddd', borderRadius: '5px', textAlign: 'center', padding: '10px', boxShadow: '1px 2px 1px #ddd' }}>
                {this.state.cadastroPaciente && <CadastroPaciente handleClose={this.handleCloseCadPaciente} empresa={this.state.cd_empresa} nm_paciente={this.state.nm_paciente} setCdPaciente={this.setCdPaciente} />}
                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold', backgroundColor: '#023575', color: '#fff', borderRadius: '5px' }} variant="subtitle1" gutterBottom>ATENDIMENTO</Typography>
                {this.state.cd_paciente == null && <Tooltip title="Não cadastrado"><ErrorOutlineIcon /></Tooltip>}
                <FormControl className={classes.input}>
                    <InputLabel htmlFor="standard-adornment-password">Nome </InputLabel>
                    <Input
                        id="standard-adornment-password"
                        value={this.state.nm_paciente}
                        onChange={this.handleChange("nm_paciente")}
                        endAdornment={
                            < InputAdornment position="end" >
                                <IconButton
                                    // disabled={state.drawer}
                                    aria-label="toggle password visibility"
                                    onClick={e => this.setState({ cadastroPaciente: true })}
                                >
                                    <PersonAddIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl >
                <FormControl className={classes.formControl}>
                    <SelectReact
                        className={classes.inputSelect}
                        styles={selectStyle}
                        placeholder='Selecione o Convênio *'
                        value={this.state.selectedConvenio}
                        onChange={this.handleChangeConvenio}
                        options={this.state.convenios}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        isDisabled={this.state.isDisabledConvenio}
                        getOptionValue={option => option.value}
                        isLoading={this.state.isLoadingConvenio}
                        name={'convenio'}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <SelectReact
                        className={classes.inputSelect}
                        styles={selectStyle}
                        placeholder='Selecione o Plano *'
                        value={this.state.selectedPlano}
                        onChange={this.handleChangePlano}
                        options={this.state.planosConvenios}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        isDisabled={this.state.isDisabledPlano}
                        getOptionValue={option => option.value}
                        isLoading={this.state.isLoadingPlano}
                        name={'plano'}
                    />
                </FormControl>
            </div>
        )
    }
}
const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid',
        borderRadius: 'none',
        boxShadow: 'none',
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};
const styles = theme => ({
    inputSelect: {
        marginTop: '15px',
        width: '250px',
        margin: '5px'
    },
    input: {
        //marginTop: '15px',
        width: '250px',
        margin: '5px'
    },

})
export default withStyles(styles)(InfoAgendamento);