import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, TextField, FormControl, InputLabel, Select, withStyles, Tooltip, Typography, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Datapicker from './Datapicker';
import CancelIcon from '@material-ui/icons/Cancel';
import api from '../../services/api';
import Alert from '../Alertas/Alert';
import AlertConcluse from '../Alertas/Concluse';
import MaskedInput from 'react-text-mask';

import SelectReact, { components } from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';

import Lista_pac from './Lista_pac'
import { format } from 'date-fns';

import axios from 'axios';
import { eventTupleToStore } from '@fullcalendar/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
class Paciente extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            lista_paciente: false,
            dt_nasc: '',
            nm_paciente_pesquisa: this.props.nm_paciente === undefined ? '' : this.props.nm_paciente,
            dataNascimentoPesquisa: null,

            isDisabledEstado: true,
            isLoadingEstado: false,
            estados: [],
            selectedEstado: null,

            isDisabledCidade: true,
            isLoadingCidade: false,
            cidades: [],
            selectedCidade: null,

            endereco: '',
            nm_mae: '',
            nm_paciente: '',
            telefone: '',
            dddTelefone: "",
            dddCelular: "",
            celular: '',
            numero: '',
            bairro: '',
            cpf: '',
            cep: '',
            sexo: '',
            email: '',
            complemento: '',
            empresa: this.props.empresa,
            pacientes: [],
            selectedPaciente: null,
            novoUsuario: false,
            cpf_pesquisa: ''
        }
        this.myRefData = React.createRef();
        this.getEstado();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCidade = this.handleChangeCidade.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
        this.handleDataNascPesquisa = this.handleDataNascPesquisa.bind(this);
        this.handlePesquisar = this.handlePesquisar.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }
    getCep = (cep) => {
        axios({
            method: 'get',
            url: `https://viacep.com.br/ws/${cep}/json/`,
        })
            .then((response) => {
                const estado = { value: response.data.uf, label: response.data.uf }
                api.post('/cidadeEstado', {
                    estado: response.data.uf,
                    cidade: response.data.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
                }).then(resp => {
                    const cidade = resp.data[0]
                    const endereco = response.data.logradouro.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
                    const bairro = response.data.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
                    this.setState({ selectedEstado: estado, selectedCidade: cidade, endereco: endereco, bairro: bairro })
                })

            }).catch(error => {
                console.log(error)
            });
    }
    handleChangeCep = (event) => {
        this.setState({ cep: event.target.value })
        if (event.target.value.length == 8) {
            this.getCep(event.target.value)
        }
    }
    /*--------------------------------------------------------------ESTADO E CIDADE ------------------------------------------------------------ */
    getEstado = () => {
        this.setState({ isLoadingEstado: true })
        api.post('/estado', {}).then(response => {
            this.setState({ estados: response.data, isDisabledEstado: false, isLoadingEstado: false })
        }).catch(error => console.log(error))
    }
    getCidade = estado => {
        api.post('/cidade', {
            estado: estado
        }).then(response => {
            this.setState({ cidades: response.data, isDisabledCidade: false, isLoadingCidade: false })
        }).catch(error => {
            console.log(error)
        })
    }
    handleChangeEstado = (event) => {
        this.setState({ selectedEstado: event, cidades: [], selectedCidade: null })
        this.getCidade(event.value)
    }

    handleChangeCidade = (event) => {
        this.setState({ selectedCidade: event })
    }
    /**-------------------------------------------------------------------------------------------------------------------------------------------*/
    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.value.toUpperCase() });
    };
    handleChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    };
    handleClosePaciente = () => {
        this.setState({ lista_paciente: false })
    }
    handleDataNascPesquisa = data => {
        console.log(data)
        this.setState({ dataNascimentoPesquisa: data })
    }
    handleDataNasc = data => {
        this.setState({ dataNascimento: data })
    }
    handleAddUser = () => {
        this.setState({ novoUsuario: true });
    }
    /*-----------------------------------------------------------------------PACIENTE------------------------------------------------------------ */
    selectedPaciente = (cd_paciente) => {
        api.post('/paciente', {
            paciente: cd_paciente
        }).then(response => {
            const estado = { value: response.data[0].cd_uf, label: response.data[0].cd_uf }
            const cidade = { value: response.data[0].cd_cidade, label: response.data[0].nm_cidade }
            this.myRefData.current.setData(response.data[0].dt_nascimento)
            this.setState({
                cd_paciente: cd_paciente,
                endereco: response.data[0].ds_endereco,
                nm_mae: response.data[0].nm_mae,
                nm_paciente: response.data[0].nm_paciente,
                dddTelefone: response.data[0].nr_ddd_fone,
                telefone: response.data[0].nr_fone,
                dddCelular: response.data[0].nr_ddd_celular,
                celular: response.data[0].nr_celular,
                numero: response.data[0].nr_endereco,
                bairro: response.data[0].nm_bairro,
                cpf: response.data[0].nr_cpf,
                cep: response.data[0].nr_cep,
                sexo: response.data[0].tp_sexo,
                dataNascimento: response.data[0].dt_nascimento,
                email: response.data[0].email,
                complemento: response.data[0].ds_complemento,
                selectedEstado: estado,
                selectedCidade: cidade,
                lista_paciente: false,
            })
        })
    }
    /**-------------------------------------------------------------------------------------------------------------------------------------------*/
    /*-----------------------------------------------------------------------Alert--------------------------------------------------------------- */
    handleCloseAlert = () => {
        this.setState({ alert: false });
    };
    /**------------------------------------------------------------------------------------------------------------------------------------------ */
    removeAcentos = str => {
        str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
        str = str.replace(/[àáâãäå]/g, "a");
        str = str.replace(/[ÈÉÊË]/g, "E");
        str = str.replace(/[óòõôö]/ui, "O");
        str = str.replace(/[úùûü]/ui, "U");
        str = str.replace(/[']/g, "");
        return str;
    }

    salvar = () => {
        this.setState({ loading: true })
        if (this.state.endereco === '') {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha o endereço `, loading: false });
            return 0;
        }

        if (this.state.dddTelefone == '' || this.state.dddCelular == '') {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha o DDD `, loading: false });
            return 0;
        }

        if (!this.validPhone(this.state.celular)) {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha um número de celular válido`, loading: false });
            return;
        }
        if (!this.validPhone(this.state.telefone)) {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha um número de telefone válido`, loading: false });
            return;
        }

        if (this.state.dataNascimento !== null) {
            var data = new Date(`${this.state.dataNascimento} `)
            var now = new Date;
            var idade = now.getFullYear() - data.getFullYear();
            if (idade > 16 && this.state.cpf === "") {
                this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha o CPF, paciente maior de 16 anos`, loading: false });
                return 0;
            }
        }
        if (this.state.nm_paciente === '' || this.state.nm_mae === '' || this.state.dataNascimento === null) {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha os campos obrigatórios `, loading: false });
            return 0;
        }
        if (this.state.cep === '' || this.state.cep === null) {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha o CEP `, loading: false });
            return 0;
        }
        if (this.state.sexo === '' || this.state.sexo === null) {
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Preencha o sexo do paciente `, loading: false });
            return 0;
        }
        if (typeof this.state.dddTelefone != "string") {
            this.setState({ dddTelefone: "" })
        }
        if (typeof this.state.dddCelular != "string") {
            this.setState({ dddCelular: "" })
        }
        if (this.state.cd_paciente == null || this.state.cd_paciente == undefined) {
            api.post('/inserirPaciente', {
                nm_paciente: this.removeAcentos(this.state.nm_paciente.toUpperCase()),
                nm_mae: this.state.nm_mae,
                dt_nascimento: this.state.dataNascimento,
                nr_ddd_fone: this.state.dddTelefone.replace("(", "").replace(")", ""),
                nr_fone: this.state.telefone.replace("-", "").replace("_", ""),
                nr_ddd_celular: this.state.dddCelular.replace("(", "").replace(")", ""),
                nr_celular: this.state.celular.replace("-", ""),
                tp_sexo: this.state.sexo,
                cidade: this.state.selectedCidade.value,
                nr_endereco: this.state.numero,
                endereco: this.removeAcentos(this.state.endereco.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()),
                complemento: this.state.complemento,
                cpf: this.removeAcentos(this.state.cpf),
                cep: this.state.cep,
                bairro: this.removeAcentos(this.state.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()),
                empresa: this.state.empresa !== undefined ? this.state.empresa : 1,
                estado: this.state.selectedEstado.value,
                email: this.state.email,
                usuario: localStorage.getItem('login')

            }).then(response => {
                this.props.setCdPaciente(response.data.cd_paciente, this.removeAcentos(this.state.nm_paciente.toUpperCase()), this.state.cpf, this.state.dataNascimento, this.state.sexo)
                this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Paciente Salvo com sucesso', loading: false });
            }).catch(error => {
                if (error.response.data.includes("unique constraint")) {
                    this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: 'CPF já preenchido por outro paciente', loading: false });
                } else {
                    this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: `${error.response.data}`, loading: false });
                }
            })
        } else {
            api.put('/paciente', {
                paciente: this.state.cd_paciente,
                nm_paciente: this.state.nm_paciente.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
                nm_mae: this.state.nm_mae,
                dt_nascimento: this.state.dataNascimento,
                nr_ddd_fone: this.state.dddTelefone.replace("(", "").replace(")", ""),
                nr_fone: this.state.telefone.replace("-", "").replace("_", ""),
                nr_ddd_celular: this.state.dddCelular.replace("(", "").replace(")", ""),
                nr_celular: this.state.celular.replace("-", ""),
                tp_sexo: this.state.sexo,
                cidade: this.state.selectedCidade.value,
                nr_endereco: this.state.numero,
                endereco: this.state.endereco,
                complemento: this.state.complemento,
                cpf: this.state.cpf,
                cep: this.state.cep,
                bairro: this.state.bairro,
                empresa: this.state.empresa !== undefined ? this.state.empresa : 1,
                estado: this.state.selectedEstado.value,
                email: this.state.email,
                usuario: localStorage.getItem('login')
            }).then(response => {
                this.props.setCdPaciente(this.state.cd_paciente, this.state.nm_paciente.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(), this.state.cpf, this.state.dataNascimento, this.state.sexo)
                this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Paciente alterado com sucesso', loading: false });
            }).catch(error => {
                if (error.response.data.includes("unique constraint")) {
                    this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: 'CPF já preenchido por outro paciente', loading: false });
                } else {
                    this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: `${error.response.data}`, loading: false });
                }
            })
        }
    }
    handleConcluseAlert = () => {
        this.props.handleClose()
    };

    handleListItemClick = (event, index) => {
        this.selectedPaciente(index);
        this.setState({ selectedPaciente: index })
    };
    validPhone = (phone) => {
        if (typeof phone == "string") {
            var regex = new RegExp('^((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
            return regex.test(phone.replace("-", "").replace("_", ""));
        } else {
            return false
        }

    }

    handlePesquisar = () => {
        if (this.state.cpf_pesquisa != '' || this.state.dataNascimentoPesquisa != null || this.state.nm_paciente_pesquisa != '') {
            this.setState({ lista_paciente: true, consultaPacientes: true })
            api.post('/searchT4', {
                nome: this.state.nm_paciente_pesquisa.toUpperCase(),
                dt_nasc: this.state.dataNascimentoPesquisa,
                cpf: this.state.cpf_pesquisa
            }).then(response => {
                this.setState({ pacientes: response.data, consultaPacientes: false, novoUsuario: false, selectedPaciente: null })
            }).catch(error => {
                console.log(error)
            })
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o nome do paciente e data de nascimento' });
        }
    }
    handlePaciente = () => {
        this.setPaciente(this.state.selectedPaciente)
    }
    handleClose = () => {
        if (this.state.selectedPaciente !== null || this.state.novoUsuario === true) {
            this.setState({
                selectedPaciente: null, novoUsuario: false, endereco: '',
                nm_mae: '',
                nm_paciente: '',
                telefone: '',
                dddTelefone: '',
                dddCelular: '',
                celular: '',
                numero: '',
                bairro: '',
                cpf: '',
                cep: '',
                sexo: '',
                email: '',
                complemento: '',
            })
        } else {
            this.props.handleClose();
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                {/* {this.state.lista_paciente && <Lista_pac handleClose={this.handleClosePaciente} setPaciente={this.selectedPaciente} nm_paciente={this.state.nm_paciente_pesquisa} dataNasc={this.state.dataNascimentoPesquisa} />} */}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={() => this.setState({ alert: false })} />}
                {this.state.alertConcluse && <AlertConcluse title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleConcluseAlert} />}
                <Dialog
                    open={true}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle className={classes.DialogTitle} id="responsive-dialog-title">{"Cadastro Paciente"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText style={{ alignItems: 'center', textAlign: 'center' }} >

                            {/* <h2>Preencha os dados do paciente</h2> */}
                            <div className={classes.search}>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '5px' }}>
                                            <TextField shrink label="CPF" variant="standard" size="small" value={this.state.cpf_pesquisa} onChange={this.handleChange('cpf_pesquisa')} />
                                        </div>
                                        <div>
                                            <Datapicker handleDataNasc={this.handleDataNascPesquisa} />
                                        </div>
                                    </div>
                                    <div>
                                        <FormControl style={{ width: '100%', marginRight: '5px' }} >
                                            <TextField label="Nome" variant="standard" size="small" value={this.state.nm_paciente_pesquisa} onChange={this.handleChange('nm_paciente_pesquisa')} />
                                        </FormControl>
                                    </div>
                                </div>
                                <div>
                                    <Tooltip title="Pesquisar">
                                        <IconButton variant="outlined" color="primary" onClick={this.handlePesquisar}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Adicionar Usuário">
                                        <IconButton variant="outlined" color="primary" onClick={this.handleAddUser}>
                                            <PersonAddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>


                            {(this.state.selectedPaciente === null && this.state.novoUsuario === false) ? <div className={classes.textfield}> <List component="nav" className={classes.root} aria-labelledby="nested-list-subheader" subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    {/* <Button className={classes.button} onClick={this.handlePaciente}>OK</Button> */}
                                    {this.state.pacientes.length > 0 && <Button className={classes.button} onClick={() => this.setState({ pacientes: [], selectedPaciente: null })}>Limpar</Button>}
                                </ListSubheader>
                            }>
                                {this.state.consultaPacientes && <CircularProgress size={100} style={{ color: '#3f51b5', marginRight: '8px' }} />}
                                {this.state.consultaPacientes == false && this.state.pacientes.length == 0 ? 'Não há registros' : ''}
                                {this.state.pacientes.map(p => <ListItem
                                    button
                                    dense={true}
                                    divider={true}
                                    selected={this.state.selectedPaciente === p.cd_paciente}
                                    onClick={(event) => this.handleListItemClick(event, p.cd_paciente)
                                    }>
                                    <ListItemText primary={p.nm_paciente} secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                Data de Nascimento -
                                            </Typography>
                                            {p.dt_nascimento}
                                            <br></br>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                Nome da Mãe -
                                            </Typography>
                                            {p.nm_mae}
                                        </React.Fragment>

                                    } />
                                </ListItem>)}
                            </List> </div> : <div className={classes.textfield}>
                                <TextField shrink className={classes.input} label="Nome Completo" variant="standard" size="small" value={this.state.nm_paciente} onChange={this.handleChange('nm_paciente')} />
                                <TextField shrink className={classes.input} label="Nome da Mãe" variant="standard" size="small" value={this.state.nm_mae} onChange={this.handleChange('nm_mae')} />
                                <div className={classes.divData}> <Datapicker handleDataNasc={this.handleDataNasc} ref={this.myRefData} /></div>
                                <TextField shrink className={classes.input} label="CPF" variant="standard" size="small" value={this.state.cpf} onChange={this.handleChange('cpf')}
                                    inputProps={{
                                        maxLength: 11,
                                    }} />
                                <div className={classes.divFone}>
                                    {/* <TextField shrink className={classes.inputDDD} label="DDD" variant="standard" size="small" type="number" value={this.state.dddTelefone} onChange={this.handleChange('dddTelefone')} /> */}
                                    {/* <TextField shrink className={classes.inputFone} label="Telefone" variant="standard" size="small" type="number" value={this.state.telefone} onChange={this.handleChange('telefone')} /> */}
                                    <FormControl>
                                        <InputLabel htmlFor="formatted-text-mask-input">DDD</InputLabel>
                                        <Input
                                            className={classes.inputDDD}
                                            value={this.state.dddTelefone}
                                            onChange={this.handleChange('dddTelefone')}
                                            // className={classes.inputFone}
                                            name="DDD"
                                            inputProps={{ 'placeholder': 'DDD' }}
                                            id="formatted-text-mask-input"
                                            inputComponent={TextMaskCustomDDD}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="formatted-text-mask-input">Telefone</InputLabel>
                                        <Input
                                            value={this.state.telefone}
                                            onChange={this.handleChange('telefone')}
                                            // className={classes.inputFone}
                                            name="Celular"
                                            inputProps={{ 'placeholder': 'Telefone' }}
                                            id="formatted-text-mask-input"
                                            inputComponent={TextMaskCustom}
                                        />
                                    </FormControl>
                                </div>
                                <div className={classes.divFone}>
                                    {/* <TextField shrink className={classes.inputDDD} label="DDD" variant="standard" size="small" type="number" value={this.state.dddCelular} onChange={this.handleChange('dddCelular')} /> */}
                                    {/* <TextField shrink className={classes.inputFone} label="Celular" variant="standard" size="small" type="number" value={this.state.celular} onChange={this.handleChange('celular')} inputComponent={TextMaskCustom} /> */}
                                    <FormControl>
                                        <InputLabel htmlFor="formatted-text-mask-input">DDD</InputLabel>
                                        <Input
                                            className={classes.inputDDD}
                                            value={this.state.dddCelular}
                                            onChange={this.handleChange('dddCelular')}
                                            // className={classes.inputFone}
                                            name="DDD"
                                            inputProps={{ 'placeholder': 'DDD' }}
                                            id="formatted-text-mask-input"
                                            inputComponent={TextMaskCustomDDD}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="formatted-text-mask-input">Celular</InputLabel>
                                        <Input
                                            value={this.state.celular}
                                            onChange={this.handleChange('celular')}
                                            // className={classes.inputFone}
                                            name="Celular"
                                            inputProps={{ 'placeholder': 'Celular' }}
                                            id="formatted-text-mask-input"
                                            inputComponent={TextMaskCustom}
                                        />
                                    </FormControl>
                                </div>
                                <TextField shrink className={classes.input} label="Email" variant="standard" size="small" type="email" value={this.state.email} onChange={this.handleChangeEmail} />
                                <TextField shrink className={classes.input} label="CEP" variant="standard" size="small" type="number" value={this.state.cep} onChange={this.handleChangeCep} />
                                <div className={classes.divSelect}>
                                    <SelectReact
                                        className={classes.inputSelect}
                                        styles={selectStyle}
                                        placeholder='Selecione o Estado'
                                        value={this.state.selectedEstado}
                                        onChange={this.handleChangeEstado}
                                        options={this.state.estados}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled={this.state.isDisabledEstado}
                                        closeMenuOnSelect={true}
                                        getOptionValue={option => option.value}
                                        isLoading={this.state.isLoadingEstado}
                                        name={'estados[]'}
                                        closeMenuOnScroll={true}
                                        pageSize={2}
                                        hideSelectedOptions={false}
                                    />
                                </div>
                                <div className={classes.divSelectCidade}>
                                    <SelectReact
                                        className={classes.inputSelect}
                                        styles={selectStyle}
                                        placeholder='Selecione a Cidade'
                                        value={this.state.selectedCidade}
                                        onChange={this.handleChangeCidade}
                                        options={this.state.cidades}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled={this.state.isDisabledCidade}
                                        closeMenuOnSelect={true}
                                        getOptionValue={option => option.value}
                                        isLoading={this.state.isLoadingCidade}
                                        isOptionDisabled={option => option.proibido == "S"}
                                        name={'cidades[]'}
                                        closeMenuOnScroll={true}
                                        pageSize={2}
                                        hideSelectedOptions={false}
                                    />
                                </div>
                                <TextField shrink className={classes.input} label="Endereço" variant="standard" size="small" value={this.state.endereco} onChange={this.handleChange('endereco')} />
                                <TextField shrink className={classes.input} label="Numero" variant="standard" size="small" type="number" value={this.state.numero} onChange={this.handleChange('numero')} />
                                <TextField shrink className={classes.input} label="Bairro" variant="standard" size="small" value={this.state.bairro} onChange={this.handleChange('bairro')} />
                                <TextField shrink className={classes.input} label="Complemento" variant="standard" size="small" type="text" value={this.state.complemento} onChange={this.handleChange('complemento')} />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-controlled-open-select-label">Sexo</InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={this.state.sexo}
                                        onChange={this.handleChange('sexo')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'M'}>Masculino</MenuItem>
                                        <MenuItem value={'F'}>Feminino</MenuItem>
                                        <MenuItem value={'I'}>Indefinido</MenuItem>
                                        <MenuItem value={'G'}>Ignorado</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.btn}>
                        <Button className={classes.button} autoFocus onClick={this.handleClose} color="primary">Voltar</Button>
                        {(this.state.selectedPaciente != null || this.state.novoUsuario) && <Button className={classes.button} onClick={this.salvar} color="primary" autoFocus>
                            {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />}
                            CONFIRMAR E SALVAR
                        </Button>}
                    </DialogActions>
                </Dialog>
            </div >
        );
    }

}

function TextMaskCustom(props) {
    const { inputRef, name, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /[1-9]/, /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}
function TextMaskCustomDDD(props) {
    const { inputRef, name, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /[1-9]/, ')']}
            showMask
        />
    );
}
const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid',
        borderRadius: 'none',
        boxShadow: 'none',
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};
const styles = theme => ({
    formControl: {
        width: '195px'
    },
    search: {
        display: 'flex',
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        justifyContent: 'space-between',
    },
    textfield: {
        marginTop: '10px',
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '5px',
    },
    btn: {

        justifyContent: 'center',
    },
    inputDDD: {
        width: '50px',
        // marginTop: '5px'
    },
    inputFone: {
        // margin: '5px',
        width: '155px',
    },
    button: {
        backgroundColor: '#ff5656',
        color: '#fff',
        padding: '5px',
        margin: '5px',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#ff5656',
        },
    },
    divSelect: {
        border: '0',
        marginTop: '15px',
        display: 'inline-flex',
        padding: '0',
        position: 'relative',
        minWidth: '0',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '195px'
    },
    divSelectCidade: {
        marginLeft: '10px',
        border: '0',
        marginTop: '15px',
        display: 'inline-flex',
        padding: '0',
        position: 'relative',
        minWidth: '0',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '195px'
    },
    divData: {
        border: '0',
        margin: '5px',
        display: 'inline-flex',
        padding: '0',
        position: 'relative',
        minWidth: '0',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '195px'
    },
    divFone: {
        margin: '5px',
        width: '195px',
        display: 'inline-flex',
    },
    input: {
        margin: '5px',
    },
    DialogTitle: {
        backgroundColor: '#023575',
        color: '#fff',
        textAlign: 'center'
    }
});


export default withStyles(styles)(Paciente)