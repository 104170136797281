import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl, Input, Paper, CircularProgress } from '@material-ui/core';
import api from '../../services/api'
import Alert from '../Alertas/Alert';
import Timer from 'react-compound-timer';
import FinishExam from './finishExam';
import SocketContext from '../../services/SocketProvider';
import Axios from 'axios'
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

class FormDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            valueConfirm: null,
            alert: false,
            alertTitle: null,
            alertBody: null,
            open: true,
            unidade: this.props.unidade,
            rooms: [],
            room: this.props.room,
            roomName: this.props.roomName,
            flow: this.props.flow,
            atendimento: this.props.atendimento,
            finishExam: false,
            prestadores: [],
            tecnico: '',
            medico: '',
            agenda: this.props.agenda,
            prestadores: [],
            prestadoresTecSuggestion: [],
            prestadoresMedSuggestion: [],
            id_med: null,
            id_tec: null
        }
        this.getRoom();
        this.getPrestadores();
        // this.setIniciarExame();
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }

    handleClose = () => {
        this.props.close()
    }
    handleChangeSelect = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
    };
    getRoom = () => {
        api.post('/room', {
            unidade: this.state.unidade
        }).then(response => {
            this.setState({ rooms: response.data })
        })
    }
    handleClickAlterar = () => {
        this.setState({ loading: true })
        api.post('/confirmaExame', {
            // atendimento: this.props.atendimento.cd_atendimento,
            // item: this.props.atendimento.cd_item_agendamento,
            cd_ped_rx: this.props.atendimento.cd_ped_rx,
            cd_itped_rx: this.props.atendimento.cd_itped_rx,
            medico: this.state.id_med,
            tecnico: this.state.id_tec
        }).then(async resp => {
            var agenda_paciente = this.state.agenda.filter(agenda => agenda.cd_paciente == this.props.atendimento.cd_paciente)
                .filter(agenda => agenda.cd_itped_rx != this.props.atendimento.cd_itped_rx).filter(agenda => agenda.sn_realizado == 'N' || agenda.sn_realizado == null)

            if (agenda_paciente.length == 0) {
                await this.sendSms()
                this.setState({ loading: false })

            } else {
                this.props.close()
                this.setState({ loading: false })
            }
        }).catch(error => {
            this.setState({ loading: false })
        })
    }
    setIniciarExame = () => {
        api.post('/inicioExame', {
            cd_room: this.state.room,
            user: localStorage.getItem('login'),
            nome: this.state.roomName,
            idflow: this.state.flow.idflow,
            unidade: this.state.unidade,
            queue: this.state.flow.idqueue
        }).then(response => {
            this.props.socket.emit('atualizaFlowCirculante')
            this.props.socket.emit('atualizaFlowTecnico')
            this.setState({ idflowIniciado: response.data.idflow })
        }).catch(error => {
            console.log(error)
        })
    }
    closeFinishExam = () => {
        this.setState({ finishExam: false })
        this.props.close()
    }
    getPrestadores = () => {
        api.post('/getPrestadores', {})
            .then(resp => {
                var usuario_prestador = resp.data.filter(users => users.cd_usuario == localStorage.getItem('login'));
                if (usuario_prestador.length > 0) {
                    let id_current = usuario_prestador[0].value;
                    let tecnico = usuario_prestador[0].label;
                    this.setState({ prestadores: resp.data, prestadoresTecSuggestion: resp.data, prestadoresMedSuggestion: resp.data, tecnico: tecnico, medico: tecnico, id_tec: id_current, id_med: id_current })
                } else {
                    this.setState({ prestadores: resp.data })
                }
            })
    }
    sendSms = () => {
        api.post('getUnitsConfirm', {
            unidade_atendimento: this.state.unidade
        }).then(respUnit => {
            console.log(respUnit.data.status_active != undefined && respUnit.data.status_active == "1")
            if (respUnit.data.status_active != undefined && respUnit.data.status_active == "1") {
                console.log("entrou")
                api.post('selectTelefoneConfirmacao', {
                    paciente: this.state.atendimento.cd_paciente
                }).then(async responseTelefone => {
                    if (responseTelefone.data.length > 0) {
                        var nr_ddd_fone = responseTelefone.data[0].nr_ddd_fone == null ? '43' : responseTelefone.data[0].nr_ddd_fone
                        var nr_fone = responseTelefone.data[0].nr_fone
                        var nr_ddd_celular = responseTelefone.data[0].nr_ddd_celular == null ? '43' : responseTelefone.data[0].nr_ddd_celular
                        var nr_celular = responseTelefone.data[0].nr_celular
                        var numero = '';
                        if (nr_celular == null && nr_fone != null) {
                            numero = nr_ddd_fone + nr_fone
                        }
                        if (nr_fone == null && nr_celular != null) {
                            numero = nr_ddd_celular + nr_celular
                        }
                        const timeElapsed = Date.now();
                        const today = new Date(timeElapsed);
                        const today_local = today.toLocaleDateString();
                        today_local.replace("/", "");
                        var dt_nascimento = new Date(this.state.atendimento.dt_nascimento)
                        var idade = this.getIdade(dt_nascimento, new Date())
                        var modalidade = new Set();
                        var agenda_paciente = this.state.agenda.filter(agenda => agenda.cd_paciente == this.props.atendimento.cd_paciente)
                        await agenda_paciente.map(agenda => {
                            switch (agenda.modalidade) {
                                case 'CT':
                                    // modalidade += "&custom10=1";
                                    modalidade.add("&custom10=1")
                                    break;
                                case 'MR':
                                    // modalidade += "&custom13=1";
                                    modalidade.add("&custom13=1")
                                    break;
                                default:
                                    break;
                            }
                        })
                        modalidade = Array.from(modalidade)
                        var url = `https://litz-estrategia.questionpro.com/gruposraclientes?custom1=${this.state.unidade}&custom2=${this.state.atendimento.cd_usuario}&custom3=${today_local.replaceAll('/', '')}&custom4=${idade}&custom5=${this.state.atendimento.tp_sexo}&custom6=${this.state.atendimento.cd_convenio}${modalidade.join('')}`
                        Axios.post(`http://bot.gruposra.com.br:9095/Whatsapp/EnviarMensagem`, {}, {
                            params: {
                                contato: "554391779938",
                                url: url,
                                token: "eyJhbGciOiJIUzI1NiJ9.eyJJc3N1ZXIiOiJJc3N1ZXIiLCJleHAiOjE3MDI5MDY2MjMsImlhdCI6MTcwMjkwNjYyM30.gVjoK4jlUURsd34XBb5h6o3buI2neTNTDBZtr65Y2q4"
                            }
                        }).then(resp => {
                            api.post('logSendConfirmation', {
                                cd_atendimento: this.state.cd_atendimento,
                                cd_multi_empresa: this.state.unidade,
                                url: url,
                                response: 'SUCCESS',
                            })
                            this.props.close()
                        }).catch(error => {
                            api.post('logSendConfirmation', {
                                cd_atendimento: this.state.cd_atendimento,
                                cd_multi_empresa: this.state.unidade,
                                url: url,
                                response: 'ERROR',
                            })
                            this.props.close()
                        })
                    }
                }).catch(error => {
                    console.log(error)
                    this.props.close()
                })
            } else {
                this.props.close()
            }
        }).catch(error => {
            console.log(error)
            this.props.close()
        })
    }
    getIdade(nascimento, hoje) {
        var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
        if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
            new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
            diferencaAnos--;
        return diferencaAnos;
    }
    renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.inputAuto}>
                <InputLabel htmlFor="standard-adornment-password">
                    Técnico
                </InputLabel>
                <Input
                    disabled={this.state.disabledInput}
                    id="standard-adornment-password"
                    // value={this.state.medExec}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                // onChange={handleChange("valueName")}
                />
            </FormControl >
        );
    }
    renderInputComponentMed = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.inputAuto}>
                <InputLabel htmlFor="standard-adornment-password">
                    Prestador
                </InputLabel>
                <Input
                    disabled={this.state.disabledInput}
                    id="standard-adornment-password"
                    // value={this.state.medExec}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                // onChange={handleChange("valueName")}
                />
            </FormControl >
        );
    }
    handleSuggestionsClearRequested = () => {
        this.setState({
            prestadoresTecSuggestion: []
        });
    };
    handleSuggestionsClearRequestedMed = () => {
        this.setState({
            prestadoresMedSuggestion: []
        });
    };
    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            prestadoresTecSuggestion: this.getSuggestions(value)
        });
    }
    handleSuggestionsFetchRequestedMed = ({ value }) => {
        this.setState({
            prestadoresMedSuggestion: this.getSuggestions(value)
        });
    }
    getSuggestionValueTec = (suggestion) => {
        this.setState({ id_tec: suggestion.value })
        return suggestion.label;
    }
    getSuggestionValueMed = (suggestion) => {
        this.setState({ id_med: suggestion.value })
        return suggestion.label;
    }
    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    getSuggestions(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return this.state.prestadores.filter(user => regex.test(user.label));
    }
    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const matches = match(suggestion.label, query);
        const parts = parse(suggestion.label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map(part => (
                        <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            </MenuItem>
        );
    };
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });
    };
    render() {
        const { classes } = this.props
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={e => this.setState({ alert: false })} />}
                {/* {this.state.finishExam && <FinishExam flow={this.state.flow} idFlowExame={this.state.idflowIniciado} tp={this.state.tp} close={e => this.setState({ finishExam: false })} unidade={this.state.unidade} finalizado={this.closeFinishExam} />} */}
                < Dialog
                    open={this.state.open}
                    // onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title">Execução do exame</DialogTitle>
                    <DialogContent className={classes.content}>
                        {/* <div className={classes.temporizador}>
                            <Timer>
                                <Timer.Hours formatValue={(value) => `${(value < 10 ? `0${value}` : value)}:`} />
                                <Timer.Minutes formatValue={(value) => `${(value < 10 ? `0${value}` : value)}:`} />
                                <Timer.Seconds formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`} />
                            </Timer>
                        </div> */}
                        <div className={classes.select}>
                            <Autosuggest
                                renderInputComponent={this.renderInputComponent}
                                suggestions={this.state.prestadoresTecSuggestion}
                                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValueTec}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={{
                                    classes,
                                    id: 'react-autosuggest-simple',
                                    label: 'Técnico',
                                    placeholder: 'Digite o nome',
                                    value: this.state.tecnico,
                                    onChange: this.handleChange('tecnico'),
                                }}
                                theme={{
                                    container: classes.formControl,
                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={options => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                            />
                            <Autosuggest
                                renderInputComponent={this.renderInputComponentMed}
                                suggestions={this.state.prestadoresMedSuggestion}
                                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequestedMed}
                                onSuggestionsClearRequested={this.handleSuggestionsClearRequestedMed}
                                getSuggestionValue={this.getSuggestionValueMed}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={{
                                    classes,
                                    id: 'react-autosuggest-simple',
                                    label: 'Prestador',
                                    placeholder: 'Digite o nome',
                                    value: this.state.medico,
                                    onChange: this.handleChange('medico'),
                                }}
                                theme={{
                                    container: classes.formControl,
                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={options => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                            />
                            {/* <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Técnico</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    inputProps={{
                                        name: 'tecnico',
                                    }}
                                    value={this.state.tecnico}
                                    onChange={this.handleChangeSelect}
                                >
                                    {this.state.prestadores.map(u => {
                                        return <MenuItem value={u.value}>{u.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Prestador</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    inputProps={{
                                        name: 'medico',
                                    }}
                                    value={this.state.medico}
                                    onChange={this.handleChangeSelect}
                                >
                                    {this.state.prestadores.map(u => {
                                        return <MenuItem value={u.value}>{u.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl> */}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Cancelar</Button>
                        {/* <Button onClick={this.handleClose} color="primary">Não realizado</Button> */}
                        <Button onClick={this.handleClickAlterar} disabled={this.state.loading} color="primary"> {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} Confirmar Exame</Button>
                    </DialogActions>
                </Dialog >
            </div >
        );
    }

}
const styles = theme => ({
    formControl: {
        width: '100%'
        // marginLeft: theme.spacing(2),
        // minWidth: 175,
    },
    inputAuto: {
        width: '100%'
    },
    temporizador: {
        fontSize: '2.5em',
        color: '#333',
        margin: '0 auto',
        padding: '0.5rem',
        textAlign: 'center',
        flex: 1,
        alignSelf: 'center'
    },
    content: {
        display: 'flex'
    },
    select: {
        flex: 1
    },
    container: {
        position: 'relative',
        width: '250px',
    },
    suggestionsContainerOpen: {
        position: 'fixed',
        zIndex: 10000000,
        width: '500px',
        left: 0,
        right: 0,
        left: '50%',
        transform: "translate(-50%, 0)"
    },
    suggestion: {
        display: 'block',
        width: '100%'
    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
}
)

const FormDialogWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <FormDialog {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withStyles(styles)(FormDialogWithSocket)